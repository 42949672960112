.navbar {
  background: rgb(254, 101, 0) !important;
  border-bottom: 1px solid #d85803;
  color: #d85803;
  padding: 0px;
}

.navbar-brand {
  /* color: rgb(254,101,0)!important; */
  font-size: 20px;
}

.logo {
  width: auto;
  height: 24px;
}

.option-hover {
  cursor: pointer;
}

.modal-wrap {
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.back-drop {
  display: flex;
  z-index: 50;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.back-drop-bg {
  background: #0e0d0d4f !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* LOGIN PAGE  */

.login-container {
  height: 100vh;
  background-color: rgb(254, 101, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  background-color: rgb(235, 226, 226);
  padding: 40px 30px;
  min-width: 400px;
  border-radius: 10px;
}

.login h2 {
  font-weight: 100;
  padding-bottom: 20px;
  text-align: center;
  font-size: 30px;
  color: #fe6500;
}

.login button {
  background-color: #d85803;
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
}
button.form-control:focus {
  background: #fe6500;
  color: #fff;
  box-shadow: none;
  border-color: #fe6500;
}

.login button:hover {
  background-color: #fe6500;
  color: #fff;
}

/* .login button{
  background-color: #fe6500;
  color: #fff
} */

.login label {
  font-size: 13px;
  margin-bottom: 5px;
}

.add-cursor {
  cursor: pointer;
  color: red;
}

.icon {
  color: #fe6500;
}

input:placeholder {
  font-size: 12px;
}

.container-fluid {
  padding: 10px 70px;
}

.button {
  margin-right: 10px;
}

.kKRXzR {
  font-size: 30px;
  padding-top: 20px;
  color: #d85803;
}

.nav-btn {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.nav-btn:hover {
  background-color: rgb(243, 106, 14);
  /* border: none; */
  color: #fff;
}

.logout-btn {
  color: #fff;
}

.logoutIcon path {
  stroke: #fff!important;
}

.invalid {
  color: red;
  font-size: 14px;
  margin: 0;
  padding-top: 0;
  padding-left: 20px;
}

.invalid-password {
  margin-bottom: 30px;
}

.clear-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgb(41, 121, 255);
  border: none; */
}

.select-lan-tbl {
  display: block;
  margin-left: 5px;
  /* width: 100%; */
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  vertical-align: middle;
  background-color: #fff;
  background-image: url('./assets/images/arrow.png');
  background-repeat: no-repeat;
  background-position: right .5rem center;
  background-size: 20px 18px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.inputs{
  display: flex;
  justify-content:center;
  margin-top: 20px;
  align-items: center;
}

.btn-winner{
  margin-left: 10px;
  background-color: rgb(254, 101, 0);
  color:#fff;
}

/* .no-of-winners, .price-type, select{
  height: 36px
} */
/* 
.no-of-winners,.price-type{
  margin-right: 10px;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 3px
} */

/* .no-of-winners{
  width: 70px
} */


.lock-button{
  margin: 20px 0;
}



/* .input-group{
  width: 25%;
  display: flex;
  align-items: center;
} */

.withdraw-page .form-control{
  margin-right: 30px;
}

.get-file-format{
  font-size: 12px;
  margin-top: 5px;
  color: grey;
}

.export-btn{
   font-size: 13px;
}

.back-btn{
   border-radius: 50%;
}